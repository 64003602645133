import {
  cache,
  createAsync,
  useSearchParams,
  type RouteDefinition,
} from '@solidjs/router';
import { For, Show } from 'solid-js';

import { gql } from '~/__gql-generated__';
import Pages from '~/components/Pages';
import styles from '~/components/Post.module.scss';
import Title from '~/components/Title';
import { client } from '~/utils/graphql';
import NotFound from './[...404]';

const PER_PAGE = 12;

function coalescePage(page?: string | null) {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return Math.max(0, Number.parseInt(page || '1', 10) - 1);
}

const POLLS = gql(`
  query Polls($limit: Int!, $offset: Int!) {
    pollsAggregate {
      count
    }
    polls(
      options: { sort: { createdAt: DESC }, limit: $limit, offset: $offset }
    ) {
      id
      title
      options(options: { sort: { weight: ASC } }) {
        id
        title
        votes
      }
      optionsAggregate {
        node {
          votes {
            sum
          }
        }
      }
    }
  }
`);

const getPolls = cache(async (page: number) => {
  'use server';

  const { data } = await client.query({
    query: POLLS,
    variables: {
      limit: PER_PAGE,
      offset: page * PER_PAGE,
    },
  });

  return {
    polls: data.polls,
    count: data.pollsAggregate.count,
  };
}, 'polls');

export const route = {
  preload: ({ location }) => {
    const parameters = new URLSearchParams(location.search.slice(1));
    return getPolls(coalescePage(parameters.get('p')));
  },
} satisfies RouteDefinition;

export default function PollList() {
  const [parameters] = useSearchParams();
  const page = () => coalescePage(parameters.p);

  const data = createAsync(() => getPolls(page()), {
    initialValue: { polls: [], count: -1 },
  });

  return (
    <Show when={data().count} fallback={<NotFound />}>
      <main>
        <Title>Enquetes</Title>
        <article>
          <h1>Enquetes</h1>
          <div class={styles.body}>
            <For each={data().polls}>
              {poll => {
                const totalVotes = poll.optionsAggregate?.node?.votes.sum ?? 0;

                return (
                  <>
                    <h2>{poll.title}</h2>
                    <ul>
                      <For each={poll.options}>
                        {option => (
                          <li>
                            {option.title} (
                            {(totalVotes > 0
                              ? option.votes / totalVotes
                              : 0
                            ).toLocaleString(import.meta.env.VITE_LOCALE, {
                              style: 'percent',
                              minimumFractionDigits: 2,
                            })}
                            )
                          </li>
                        )}
                      </For>
                    </ul>
                    <p>
                      {totalVotes.toLocaleString(import.meta.env.VITE_LOCALE)}{' '}
                      {totalVotes >= 2 ? 'votos' : 'voto'}
                    </p>
                    <hr />
                  </>
                );
              }}
            </For>
          </div>
        </article>
        <Pages page={page()} total={Math.ceil(data().count / PER_PAGE)} />
      </main>
    </Show>
  );
}
